.initial-about-container {
    position: absolute;
    top: 60px;
    display: grid;
    place-content: center;
    height: fit-content;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: min-content;
    grid-gap: 20px;
    transform: translateY(100%);
    opacity: 0;
    animation: initial-fade-and-roll-in 0.5s ease-in-out forwards;
}

@keyframes initial-fade-and-roll-in {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.about-container {
    position: absolute;
    top: 60px;
    display: grid;
    place-content: center;
    height: fit-content;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: min-content;
    grid-gap: 20px;
}

.about-container-out {
    position: relative;
    padding-top: 15px;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: min-content;
    grid-gap: 20px;
    justify-content: center;
    place-items: center;
    transform: translateX(0%);

    /* Animate the component as it comes into view */
    animation: fade-and-roll-in 0.5s ease-in-out forwards;
}

@keyframes fade-and-roll-out {
    from {
        transform: translateX(-0%);
        opacity: 0;
    }
    to {
        transform: translateX(-100%);
        opacity: 1;
    }
}

.subsequent-about-container {
    position: absolute;
    top: 60px;
    display: grid;
    place-content: center;
    height: fit-content;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: min-content;
    grid-gap: 20px;
    transform: translateX(-100%);
    opacity: 0;

    /* Animate the component as it comes into view */
    animation: subsequent-fade-and-roll-in 0.5s ease-in-out forwards;
}

@keyframes subsequent-fade-and-roll-in {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* =========================================================================== */

.about-title {
    grid-column: 2;
    grid-row: 1;
    text-align: center;
}

.about-header-elements {
    padding: 20px;
}

.my-image {
    border: 0.5dvw solid;
    border-radius: 75%;
    max-height: clamp(100px, 20vw, 400px);
}

/* =========================================================================== */

.about-body {
    height: 100%;
    max-width: clamp(20dvw, 80vw, 100dvw);
    grid-column: 2;
    grid-row: 2;
    text-align: center;
}
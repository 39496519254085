.navBarMain {
    position: absolute;
    background: #9ED2C6;
    display: flex;
    align-items: center;
    padding: clamp(5px, 1vw, 20px);
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    z-index: 1;
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .navBarMain {
        position: absolute;
        background: #9ED2C6;
        display: flex;
        align-items: center;
        padding: clamp(5px, 1vw, 20px);
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        z-index: 1;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navBarMain {
        position: absolute;
        background: #9ED2C6;
        display: flex;
        align-items: center;
        padding: clamp(5px, 1vw, 20px);
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        z-index: 1;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .navBarMain {
        position: absolute;
        background: #9ED2C6;
        display: flex;
        align-items: center;
        padding: clamp(5px, 1vw, 20px);
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        z-index: 1;
    }
}

@media only screen and (max-width: 460px) {
    .navBarMain {
        position: absolute;
        background: #9ED2C6;
        display: flex;
        align-items: center;
        padding: clamp(5px, 1vw, 20px);
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        z-index: 1;
    }
}

.nav-header {
    font-family: sans-serif;
    background: #9ED2C6;
    text-shadow: 1px 1px 1px black;
    width: 100%;
    height: 100%;
}

.navbar-brand {
    position: relative;
    transition: none;
    font-size: 1.5rem;
    color: #333;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.nav-link {
    background: #9ED2C6;
    padding: 5px;
    font-size: 1rem;
    color: #333;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.3s;
}

.nav-wording {
    font-family: sans-serif;
    font-size: 1rem;
    color: white;
    text-shadow: 1px 1px 1px black;
    padding: 5px;
    border-radius: 10px;
}

.nav-wording:hover {
    background: #8CC1B9
}

.body {
    position: absolute;
    z-index: -1;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: lightblue;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}
.contact-body {
    position: absolute;
    top: 60px;
    display: grid;
    padding-top: 10px;
    place-items: center;
    width: 90%;
    height: fit-content;
    background: transparent;
    transform: translateX(100%);
    opacity: 0;
    animation: right-fade-and-roll-in 0.5s ease-in-out forwards;
}

@keyframes right-fade-and-roll-in {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes left-fade-and-roll-out {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.form-contact {
    display: grid;
    place-items: center;
    grid-gap: 20px;
    background: transparent;
}

.form-header {
    display: grid;
    place-items: center;
    border-bottom: 1px solid darkslategrey;
}

.form-element {
    position: relative;
    width: 60%;
}

.form-input {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 1vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    border-bottom: 1px solid #ccc;
    width: 100%;
    margin: 10px;
}

.input-label {
    position: absolute;
    left: 15px;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 200ms cubic-bezier(0.4, 0, 0, 2.1);
}

.form-input:focus, .form-input:valid {
    outline: none;
    border: 2px solid mediumspringgreen;
}

.form-input:focus ~ label, .form-input:valid ~ label {
    transform: translateY(-80%) scale(0.8);
    font-weight: bold;
}

.form-input.invalid {
    border: 2px solid red;
}

.form-input.invalid ~ label {
    transform: translateY(-80%) scale(0.8);
    font-weight: bold;
}

.form-button {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    margin: 10px;
}

.email-message {
    padding-top: 10px;
    text-align: center;
    color: forestgreen;
}
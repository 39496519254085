.left-projects-body {
    top: 60px;
    text-align: center;
    position: absolute;
    display: grid;
    grid-template-rows: auto auto;
    height: fit-content;
    width: 85%;
    transform: translateX(-100%);
    opacity: 0;
    animation: left-fade-and-roll-in 0.5s ease-in-out forwards;
}

@keyframes left-fade-and-roll-in {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.right-projects-body {
    top: 60px;
    text-align: center;
    position: absolute;
    display: grid;
    grid-template-rows: auto auto;
    height: fit-content;
    width: 85%;
    transform: translateX(-100%);
    opacity: 0;
    animation: right-fade-and-roll-in 0.5s ease-in-out forwards;
}

@keyframes right-fade-and-roll-in {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.projects-body {
    top: 60px;
    text-align: center;
    position: absolute;
    display: grid;
    grid-template-rows: auto auto;
    height: fit-content;
    width: 85%;
}

.projects-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 10px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: clamp(2px, 1vw, 5px);
    padding-top: 10px;
}

.game-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: clamp(128px, 50vw, 640px);
    height: 100%;
    padding: 5px;
    cursor: pointer;
    transform-origin: center;
    transition: 250ms all;
}

.game-card > img {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    width: clamp(256px, 50vw, 512px);
    border-radius: 7px;
}

.game-card:hover {
    opacity: 1 !important;
    transform: scale(1.05);
}

.game-card:hover ~ .game-card {
    transform: translateX(-20px);
}

/* =========================================================================== */

.expanded-project-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    top: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.interior-project-page-view {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    height: 100%;
    background: slategrey;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto auto auto auto;
    padding: 10px;
}

.interior-project-page-view-mobile {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    height: 100%;
    background: slategrey;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto auto auto auto;
    padding: 10px;
}

.project-view-header {
    background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
    );
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    z-index: 1;
    height: fit-content;
    display: grid;
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 5;
}

.project-view-header-title {
    height: 100%;
    display: grid;
    justify-content: flex-start;
    place-items: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    grid-column-start: 1;
    grid-column-end: 5;
}


.project-view-header-exit {
    height: 100%;
    display: grid;
    justify-content: right;
    place-items: center;
    padding-right: 10px;
    grid-column-start: 5;
    grid-column-end: 6;
}

.exit-button {
    background: transparent;
    color: black;
    font-size: larger;
    border: transparent;
}

.exit-button:hover {
    color: #ffffff;
    background-color: transparent;
}

.exit-button:active:focus {
    color: #ffffff;
    background-color: transparent;
}

.project-view-image-section {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    padding: 2%;
    max-height: 30vmax;
    display: grid;
    place-content: center;
    gap: 1vmin;
    overflow: hidden;
    grid-template-columns:  20% 80%;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 4;
}

.project-view-image-section-mobile {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    padding: 2%;
    max-height: 30vmax;
    display: grid;
    place-content: center;
    gap: 1vmin;
    overflow: hidden;
    grid-template-rows:  20% 80%;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 5;
}

.project-images-holder {
    border: 2px solid darkslategrey;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: 0.5s;
    overflow-y: scroll;
}

.project-images-holder-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    transition: 0.5s;
    overflow-y: scroll;
}

.project-images {
    transition: 0.5s;
}

.project-images:hover > :not(:hover) {
    opacity: .4;
}

.project-selected-image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.project-selected-image {
    border: 2px solid darkslategrey;
    max-height: 100%;
    width: auto;
    max-width: 100%;
}

.project-view-description {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    overflow: auto;
    padding: 1rem;
    max-height: 30vmax;
    display: grid;
    text-align: center;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 4;
    grid-column-end: 4;
    grid-template-rows: 15% 85%;
}

.project-view-description-mobile {
    border: 2px solid darkslategrey;
    overflow: auto;
    padding: 1rem;
    max-height: 30vmax;
    display: grid;
    text-align: center;
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 5;
}

@media screen and (min-width: 1100px) {
    .project-view-description-header {
        border-bottom: 2px solid darkslategrey;
        padding-top: 5px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: bold;
        font-size: clamp(20px, 1vw, 30px);
        line-height: 1.2em;
        min-height: fit-content;
        max-width: 100%;
    }
}

@media screen and (max-width: 1100px) and (min-width: 800px) {
    .project-view-description-header {
        border-bottom: 2px solid darkslategrey;
        padding-top: 5px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: bold;
        font-size: clamp(10px, 2vw, 12px);
        line-height: 1.2em;
        min-height: fit-content;
        max-width: 100%;
    }
}

.project-view-description-text {
    font-size: 20px;
    line-height: 1.2em;
    max-width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding-left: 5px;
    padding-right: 5px;
}

.project-view-description-header p, .project-view-description-text p {
    margin-top: 0;
}

.soon-interior-project-page-view {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    height: 100%;
    background: slategrey;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto auto;
    padding: 10px;
}

.soon-project-view-description {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    overflow: auto;
    padding: 1rem;
    max-height: 30vmax;
    display: grid;
    text-align: center;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-template-rows: 15% 85%;
    height: 100%;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: auto;
}

.soon-project-view-description-header {
    margin: 10px;
    padding-top: 10px;
    border-bottom: 2px solid darkslategrey;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: bold;
    font-size: clamp(20px, 1vw, 30px);
    line-height: 1.2em;
    min-height: fit-content;
    max-width: 100%;
    padding-bottom: 10px;
}

.soon-project-view-description-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 1.2em;
    max-width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding-left: 5px;
    padding-right: 5px;
}

.project-view-itch {
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row-start: 5;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 5;
}

.project-view-itch-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
    );
    backdrop-filter: blur(2vmin);
    -webkit-backdrop-filter: blur(2vmin);
    box-shadow: 0 0.8vmax 3.2vmin 0 rgba(0, 0, 0, 0.36);
    border: 2px solid darkslategrey;
    border-radius: clamp(2.4vmin, 1.5vw, 2.8vmin);
    z-index: 1;
    grid-row-start: 6;
    grid-column-start: 1;
    grid-column-end: 5;
}